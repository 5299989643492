import React from "react";
import { graphql } from "gatsby";
import CmsPageBlocks from "cmspageblocks";
import Layout from "../components/Layout";
import Head from "../components/Layout/Head";
// import Head from 'components/head';
// import HouseHighlight from 'components/houseHighlight';

const PageTemplate = ({ data, pageContext }) => {
  const page = JSON.parse(data.buroBorkPage.field);
  const houses = data.allBuroBorkHouse.edges;

  const { title, seo_title, meta_description } = page;

  return (
    <Layout locale={pageContext.locale}>
      <Head
        locale={pageContext.locale}
        pageTitle={seo_title ? seo_title : title}
        pageDescription={meta_description}
      />
      <CmsPageBlocks content={page} houses={houses} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BuroBorkPagebySlug($id: String!) {
    buroBorkPage(id: { eq: $id }) {
      id
      field
    }
    allBuroBorkHouse {
      edges {
        node {
          id
          slug
          house_code
          field
        }
      }
    }
  }
`;

export default PageTemplate;
